import { bsc } from './bsc'
import { bscTestnet } from './bscTestnet'
import { bitcoin } from './btc'
import { bitcoinTestnet } from './btcTestnet'
import { ethereum } from './ethereum'
import { goerli } from './goerli'
import { morphTestnet } from './morphTestnet'
import { shapeTestnet } from './shapeTestnet'
import { storyTestnet } from './storyTestnet'
import { zeta } from './zeta'
import { zetaTestnet } from './zetaTestnet'
import { zuluTestnet } from './zuluTestnet'
import { duckTestnet } from './duckTestnet'
import { shape } from './shape'

export {
  bitcoin,
  bitcoinTestnet,
  bsc,
  bscTestnet,
  ethereum,
  goerli,
  morphTestnet,
  shapeTestnet,
  shape,
  storyTestnet,
  zeta,
  zetaTestnet,
  zuluTestnet,
  duckTestnet,
}
export const chainMap = {
  shape,
  zuluTestnet,
  shapeTestnet,
  duckTestnet,
  morphTestnet,
  storyTestnet,
}
export const chains = Object.values(chainMap)
export const chainsSetup = Object.values({
  ...chainMap,
  // ethereum,
  // shapeTestnet,
  // morphTestnet,
  // bsc,
  // bscTestnet,
})

export const getChain = (chainId: number) => {
  return chainsSetup.find((chain) => chain.id === chainId)
}

export enum ChainId {
  ZETA = zeta.id,
  SHAPE = shape.id,
  ETHEREUM = ethereum.id,
  GOERLI = goerli.id,
  ZETA_TESTNET = zetaTestnet.id,
  BSC = bsc.id,
  BSC_TESTNET = bscTestnet.id,
  ZULU_TESTNET = zuluTestnet.id,
  MORPH_TESTNET = morphTestnet.id,
  SHAPE_TESTNET = shapeTestnet.id,
  STORY_TESTNET = storyTestnet.id,
  DUCK_TESTNET = duckTestnet.id,
}

export const DEFAULT_CHAIN = shape
export const DEFAULT_CHAINID = shape.id
